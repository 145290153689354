import {domLoaded} from "../base/onLoad";

domLoaded(() => {
   const gfields = document.querySelectorAll('.gfield');

   gfields.forEach((gfield) => {
      const input = gfield.querySelector('input,textarea');

      if(!input) return;

      const label = gfield.querySelector('label');

      if(!label) return;


      input.addEventListener('focus', () => {
          gfield.classList.add('active');
      });

      input.addEventListener('blur', () => {
          gfield.classList.remove('active');
      });

      input.addEventListener('change', () => {
          if(input.value.length > 0) {
              gfield.classList.add('filled');
          } else {
              gfield.classList.remove('filled');
          }
      });

      if(input.value.length > 0) {
          gfield.classList.add('filled');
      }

      if(input.hasAttribute('required')) {
          gfield.classList.add('required');
      }

      if(input.hasAttribute('disabled')) {
          gfield.classList.add('disabled');
      }

   });
});