import {onLoad} from "../base/onLoad";

onLoad(() => {
    const hasScrolledItems = document.querySelectorAll('[data-has-scrolled]');

    if(hasScrolledItems.length === 0) {
        return;
    }

    document.addEventListener('scroll', () => {
       const scrollLocation = window.scrollY;
       const value = scrollLocation>0?'true':'false';

        hasScrolledItems.forEach(item => {
            item.setAttribute('data-has-scrolled', value);
        });
    });
})