window.addEventListener("load", function () {
    var afterPopupOpener = document.querySelectorAll('.card-content');
    var popup = document.querySelector('.popup');
    var cardBg = popup === null || popup === void 0 ? void 0 : popup.querySelector('.card-backdrop');

    function openModal(togglePopup, cardContent) {
        var card = popup.querySelector('.card');

        //create the Modal card content

        var newCard = document.createElement('div');
        var newCardContent = cardContent;
        newCard.className = 'card-content';
        newCard.innerHTML = cardContent.innerHTML;

        //insert content in the card

        card.innerHTML = '<i class="fa-solid fa-xmark close absolute top-0 right-0 m-6"></i>';
        card.appendChild(newCardContent);

        //make the inserted icon the close button

        var closeButton = card.querySelector('.close');
        closeButton === null || closeButton === void 0 ? void 0 : closeButton.addEventListener('click', function () {
            popup.classList.remove('open');
        });

        //open the Modal

        popup.classList.add('open');
    }

    //fill and open the Modal on clicking the element that has
    //the 'open-popup' class or is a Google Maps Marker

    afterPopupOpener.forEach(function (Opener) {
        var togglePopup = Opener.previousElementSibling;
        togglePopup.addEventListener('click', function () {
            openModal(togglePopup, Opener);
        });
    });

    //close the popup when you click outside the popup element

    cardBg === null || cardBg === void 0 ? void 0 : cardBg.addEventListener('click', function () {
        popup.classList.remove('open');
    });
});
