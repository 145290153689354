import Splide from '@splidejs/splide';
import {onLoad} from "../../base/onLoad";
import {AutoScroll} from "@splidejs/splide-extension-auto-scroll";

// const logo_splide = new Splide('.logo_slider', {
//     autoScroll: {
//         speed: 1,
//     },
//     pauseOnHover: true,
//     autoWidth: true,
//     rewind: true,
//     type: 'loop',
// });
//
// logo_splide.on('autoplay:playing', function (rate) {
//     console.log(rate); // 0-1
// });
//
// logo_splide.mount({AutoScroll});

onLoad(() => {
    const splideClass = '.klanten-slider';
    const elements = document.querySelectorAll(splideClass);

    elements.forEach(element => {
        const splide = new Splide(element, {
            // type: 'loop',
            // rewind: true,
            // perPage: 1,
            // padding: '4rem',
            // gap: '4rem',
            // arrows: false,
            // width: 'max(100%, 400px)',
            autoScroll: {
                speed: 1,
            },
            //pauseOnHover: true,
            autoWidth: true,
            rewind: true,
            perPage: -1,
            type: 'loop',
        });

        splide.on('autoplay:playing', function (rate) {
            console.log(rate); // 0-1
        });

        splide.mount({AutoScroll});

        // const prev = element.querySelector('.prev');
        // const next = element.querySelector('.next');
        //
        // prev.addEventListener('click', () => {
        //     splide.go('<');
        // });
        //
        // next.addEventListener('click', () => {
        //     splide.go('>');
        // });
    });
})