import {onLoad} from "../base/onLoad";

onLoad(() => {
    // menu toggles
    const toggles = document.querySelectorAll('[menu-toggle]');
    // menu items
    const menu = document.querySelector('[menu]');
    
    let isOpen = false;
    const activeClass = 'active';

    function toggleMenu() {
        const shouldOpen = isOpen;
        
        if(shouldOpen) {
            if(menu) {
                menu.classList.add(activeClass);
            }

            toggles.forEach(toggle => toggle.classList.add(activeClass));
            document.body.classList.add('menu-open');
        } else {
            if(menu) {
                menu.classList.remove(activeClass);
            }

            toggles.forEach(toggle => toggle.classList.remove(activeClass));
            document.body.classList.remove('menu-open');
        }

        toggles.forEach(toggle => {
            toggle.setAttribute('aria-expanded', shouldOpen);
        });
    }

    // loop over toggles
    toggles.forEach(toggle => {        
        // on click
        toggle.addEventListener('click', () => {
            isOpen = !isOpen;
            toggleMenu();
        });
    });

    buildMenu();
})

function buildMenu() {
    const expandIcon = '<i class="far fa-chevron-down"></i>';
    const collapseIcon = '<i class="far fa-chevron-up"></i>';

    const menu = document.querySelector('.mobile-navigation');

    if(!menu) {
        return;
    }

    const menuItemsWithSubMenus = menu.querySelectorAll('.menu-item-has-children');

    if(!menuItemsWithSubMenus) {
        return;
    }

    menuItemsWithSubMenus.forEach(menuItem => {
        const plus = document.createElement('button');
        plus.innerHTML = expandIcon;
        plus.classList.add('expand-menu');

        const link = menuItem.querySelector('a');

        const firstItem = menuItem.querySelector(':first-child');
        firstItem.appendChild(plus);

        const submenu = menuItem.querySelector('.sub-menu');

        link.addEventListener('click', function (event) {
            event.preventDefault();
            toggle();
        });

        const parentClasses =  menuItem.classList.toString();

        const newParentLi = document.createElement('li');
        newParentLi.classList.value = parentClasses
        newParentLi.classList.remove('menu-item-has-children');
        newParentLi.classList.remove('current_page_ancestor');
        const newParentLink = document.createElement('a');
        newParentLink.setAttribute('href', link.getAttribute('href'));
        newParentLink.innerText = link.innerText;

        newParentLi.appendChild(newParentLink);

        submenu.prepend(newParentLi);


        function toggle() {
            submenu.classList.toggle('active');
            if(!submenu.classList.contains('active')) {
                plus.innerHTML = expandIcon;
            } else {
                plus.innerHTML = collapseIcon;
            }
        }
    })
}