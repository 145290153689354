import Splide from '@splidejs/splide';
import {onLoad} from "../../base/onLoad";

onLoad(() => {
    const splideClass = '.dienst_slider';
    const elements = document.querySelectorAll(splideClass);

    elements.forEach(element => {
        const splide = new Splide(element, {
            type: 'loop',
            rewind: true,
            perPage: 1,
            padding: '4rem',
            gap: '4rem',
            arrows: false,
            width: 'max(100%, 400px)',
        });

        splide.mount();

        const prev = element.querySelector('.prev');
        const next = element.querySelector('.next');

        prev.addEventListener('click', () => {
            splide.go('<');
        });

        next.addEventListener('click', () => {
            splide.go('>');
        });
    });
})