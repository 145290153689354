import {onLoad} from "../base/onLoad";

onLoad(() => {
    const filterOptions = document.querySelectorAll('.filter-option');
    const inspirationArchives = document.querySelectorAll('.inspiratie-archive');

    filterOptions.forEach(option => {

        option.addEventListener('click', () => {
            filterOptions.forEach(unactive => {
                unactive.classList.remove('active');
            })

            option.classList.add('active');

            inspirationArchives.forEach(archive => {
                archive.classList.remove('open');

                if (archive.id === option.id) {
                    archive.classList.add('open');
                }
            })
        })
    })
})